/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 scene.glb -T --shadows
Author: Alexios Apokaukos (https://sketchfab.com/Alexios_Apokaukos)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/triumph-spitfire-mkiii-9c5c1a73f65046459051f9d0bf1132a0
Title: Triumph Spitfire MKIII
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { angelToRadians } from '../../utils/Angel'

export default function CarCompressed(props) {
  const { nodes, materials } = useGLTF('/404-not-found/public/models/car/scene-transformed.glb')
  const group = useRef()
  return (
    <group ref={group} dispose={null} scale={0.75} position={[-4, 0, 0]} rotation={[0, angelToRadians(90), 0]}>
      <group position={[0.11, 0.67, 0.42]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={materials.TRIUMPH_SPITFIRE_CHROME} />
        <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.steeringwheel} />
        <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.carbody} />
        <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry} material={materials.TRIUMPH_SPITFIRE_DASHBOARD} />
        <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={materials.headlight_light} />
        <mesh castShadow receiveShadow geometry={nodes.Object_9.geometry} material={materials.number_plate} />
        <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={materials.number_plate} />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Object_14.geometry} material={materials.wheel_rimrim} />
        <mesh castShadow receiveShadow geometry={nodes.Object_15.geometry} material={materials.tire} />
      </group>
      <group position={[0.11, 0.67, 0.45]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Object_27.geometry} material={materials.TRIUMPH_SPITFIRE_CHROME} />
        <mesh castShadow receiveShadow geometry={nodes.Object_28.geometry} material={materials.number_plate} />
      </group>
      <group position={[-0.66, 0.32, 1.34]} rotation={[Math.PI / 2, -0.02, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Object_37.geometry} material={materials['28_int_gauges']} position={[0.01, 0, -0.05]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow geometry={nodes.Object_39.geometry} material={materials.headglass} position={[0.67, -1.34, 0.31]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow geometry={nodes.Object_41.geometry} material={materials.tire} position={[0.67, -3.75, 0.33]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
      </group>
      <mesh castShadow receiveShadow geometry={nodes.Object_12.geometry} material={materials.TRIUMPH_SPITFIRE_WINDOWS} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_17.geometry} material={materials.TRIUMPH_SPITFIRE_floor} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_19.geometry} material={materials.underbody} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_21.geometry} material={materials.leather} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_23.geometry} material={materials.HEADLIGHT_glass} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_25.geometry} material={materials.wheel_rimrim} position={[-0.64, 0.88, 0.39]} rotation={[0.71, -0.57, -0.28]} scale={[4.51, 0.93, 1.26]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_30.geometry} material={materials['underbody.001']} position={[0.11, 0.67, 0.42]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_32.geometry} material={materials.TRIUMPH_SPITFIRE_CHROME} position={[-0.21, 0.27, -1.82]} rotation={[-Math.PI / 2, 0, Math.PI]} />
      <mesh castShadow receiveShadow geometry={nodes.Object_34.geometry} material={materials.TRIUMPH_SPITFIRE_CHROME} position={[0.32, 0.67, 0.42]} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('/404-not-found/public/models/car/scene-transformed.glb')
