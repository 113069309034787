import { Html } from '@react-three/drei';
import { Canvas } from '@react-three/fiber'
import { Suspense } from 'react'
import './App.css';

import Three from "./components/three"

function App() {
    return (
        <>
            <div className='wrapper'>
                <div className='error-message'>
                    <h1>Sad, it's 404 :v</h1>
                    <p>Back to the main page <a href="https://aqi.co.id">AsiaQuest</a></p>
                </div>
                <Canvas className='three-canvas-container' shadows>
                    <Suspense fallback={null}>
                        <Three/>
                    </Suspense>
                </Canvas>
            </div>
        </>
    )
}

export default App;
