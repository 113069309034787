import React, { useEffect, useRef } from 'react'
import { Environment, OrbitControls, PerspectiveCamera } from '@react-three/drei'
import {angelToRadians} from '../../utils/Angel'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import gsap from 'gsap'
import CarCompressed from './carCompressed'

const Three = () => {
    const orbitControlRef = useRef(null)

    useFrame((state)=>{
        // everything pass here will be rendered 60fps
        // render is actually a loop process. So if we pass a function here, then it will be rendered once a system is ready to process the frame
        // the speed of a system can render a frame is limited by the frame rate
        if(orbitControlRef.current){
            const {x, y} = state.mouse
            const xMaxAzimuthal = -x * angelToRadians(180)
            const yMaxPolar = (y + 1) * angelToRadians(90 - 30)
            orbitControlRef.current.setAzimuthalAngle(xMaxAzimuthal)
            orbitControlRef.current.setPolarAngle(yMaxPolar)
            orbitControlRef.current.update()
        }
    })

    useEffect(() => {
        if(orbitControlRef.current){
            console.log('orbitControlRef:', orbitControlRef.current)

        }
    }, [orbitControlRef.current])

    // ball animation
    const ballRef = useRef(null)
    useEffect(()=>{
        if(ballRef.current){
            console.log(ballRef)

            // timeline
            const timeline = gsap.timeline()

            // x axis motion
            timeline.to(ballRef.current.position, {
                x: 2,
                duration: 2,
                ease: 'power2.out'
            })

            // y axis motion
            timeline.to(ballRef.current.position, {
                y: 0.5,
                duration: 1,
                ease: 'bounce.out'
            }, '<')
        }
    }, [ballRef.current])

    return (
        <>
            {/* camera */}
            <PerspectiveCamera makeDefault position={[0, 1, 5]}/>

            {/* take over the default camera */}
            <OrbitControls ref={orbitControlRef} minPolarAngle={angelToRadians(60)} maxPolarAngle={angelToRadians(80)}/>

            {/* light */}
            <ambientLight args={["#fff", 0.25]} />
            <spotLight args={['#f0e43e', 1.5, 15, angelToRadians(45), 0.4]} position={[-3, 1, 0]} castShadow/>
            <pointLight args={['#f03ea3', 1.5, 15, angelToRadians(90), 0.2]} position={[-4, 8, 0]} castShadow/>

            {/* ball */}
            <mesh ref={ballRef} position={[-4, 2, 0]} castShadow>
                <sphereGeometry args={[0.5, 32, 20]} />
                <meshStandardMaterial color="#fff" metalness={0.75} roughness={0.25}/>
            </mesh>

            {/* box */}
            <mesh position={[3, 0.5, 0]} castShadow receiveShadow>
                <boxGeometry args={[0.5, 3, 3]} />
                <meshStandardMaterial color="#fff" metalness={0.75} roughness={0.25}/>
            </mesh>

            {/* car */}
            <CarCompressed />

            {/* plane */}
            <mesh rotation={[-(angelToRadians(90)), 0, 0]} receiveShadow>
                <planeGeometry args={[20, 20]}/>
                <meshStandardMaterial color="#f0593e"/>
            </mesh>

            {/* environment */}
            <Environment background>
                <mesh>
                    <sphereGeometry args={[50, 100, 100]}/>
                    <meshBasicMaterial color="#f0973e" side={THREE.BackSide}/>
                </mesh>
            </Environment>
        </>
    )
}

export default Three